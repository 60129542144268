import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation, AnimatePresence, useMotionValue, useSpring } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Menu, Calendar, ArrowRight, X } from 'lucide-react';

const CustomCursor = () => {
  const cursorX = useMotionValue(-100)
  const cursorY = useMotionValue(-100)
  const cursorRotation = useMotionValue(0)
  const [prevPosition, setPrevPosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    const moveCursor = (e) => {
      // Calculate rotation based on movement direction
      const newX = e.clientX
      const newY = e.clientY
      
      if (prevPosition.x !== 0 && prevPosition.y !== 0) {
        const deltaX = newX - prevPosition.x
        const deltaY = newY - prevPosition.y
        
        if (Math.abs(deltaX) > 0 || Math.abs(deltaY) > 0) {
          const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI)
          cursorRotation.set(angle)
        }
      }
      
      setPrevPosition({ x: newX, y: newY })
      cursorX.set(newX)
      cursorY.set(newY)
    }
    
    document.addEventListener("mousemove", moveCursor)

    return () => {
      document.removeEventListener("mousemove", moveCursor)
    }
  }, [cursorX, cursorY, cursorRotation, prevPosition])

  const springConfig = { damping: 25, stiffness: 700 }
  const cursorXSpring = useSpring(cursorX, springConfig)
  const cursorYSpring = useSpring(cursorY, springConfig)
  const rotationSpring = useSpring(cursorRotation, { damping: 50, stiffness: 400 })

  return (
    <motion.div
      className="nechepso-cursor"
      style={{
        translateX: cursorXSpring,
        translateY: cursorYSpring,
        rotate: rotationSpring,
        x: "-50%",
        y: "-50%",
      }}
    >
      <div className="nechepso-cursor-arrow" />
    </motion.div>
  )
}

const ConstellationBackground = () => {
  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext("2d")
    let animationFrameId
    let particles = []
    let mouseX = 0
    let mouseY = 0

    // Set canvas dimensions
    const setCanvasDimensions = () => {
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight
    }

    setCanvasDimensions()
    window.addEventListener("resize", setCanvasDimensions)

    // Track mouse position on document instead of canvas
    const handleMouseMove = (e) => {
      mouseX = e.clientX
      mouseY = e.clientY
    }
    
    const handleTouchStart = (e) => {
      if (e.touches.length > 0) {
        mouseX = e.touches[0].clientX
        mouseY = e.touches[0].clientY
      }
    }
    
    const handleTouchMove = (e) => {
      if (e.touches.length > 0) {
        mouseX = e.touches[0].clientX
        mouseY = e.touches[0].clientY
      }
    }

    // Add event listeners to document instead of canvas
    document.addEventListener("mousemove", handleMouseMove)
    document.addEventListener("touchstart", handleTouchStart)
    document.addEventListener("touchmove", handleTouchMove)

    // Create particles
    const createParticles = () => {
      particles = []
      const isMobile = window.innerWidth < 768;
      const particleCount = isMobile 
        ? Math.floor((window.innerWidth * window.innerHeight) / 22500)
        : Math.floor((window.innerWidth * window.innerHeight) / 22500);

      for (let i = 0; i < particleCount; i++) {
        particles.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          size: Math.random() * 2 + 1,
          speedX: (Math.random() - 0.5) * 0.2,
          speedY: (Math.random() - 0.5) * 0.2,
          opacity: Math.random() * 0.5 + 0.3,
        })
      }
    }

    createParticles()

    // Draw particles and connections
    const render = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      // Draw particles
      particles.forEach((particle, index) => {
        // Update position
        particle.x += particle.speedX
        particle.y += particle.speedY

        // Wrap around edges
        if (particle.x < 0) particle.x = canvas.width
        if (particle.x > canvas.width) particle.x = 0
        if (particle.y < 0) particle.y = canvas.height
        if (particle.y > canvas.height) particle.y = 0

        // Draw particle
        ctx.beginPath()
        ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2)
        ctx.fillStyle = `rgba(255, 255, 255, ${particle.opacity})`
        ctx.fill()

        // Draw connections to nearby particles
        for (let j = index + 1; j < particles.length; j++) {
          const dx = particles[j].x - particle.x
          const dy = particles[j].y - particle.y
          const distance = Math.sqrt(dx * dx + dy * dy)

          if (distance < 100) {
            ctx.beginPath()
            ctx.moveTo(particle.x, particle.y)
            ctx.lineTo(particles[j].x, particles[j].y)
            
            // Make particle connections brighter on all devices
            ctx.strokeStyle = `rgba(106, 154, 212, ${0.25 * (1 - distance / 100)})`
            ctx.lineWidth = 0.7;
            ctx.stroke()
          }
        }

        // Draw connections to mouse
        const dx = mouseX - particle.x
        const dy = mouseY - particle.y
        const distance = Math.sqrt(dx * dx + dy * dy)
        
        if (distance < 150) {
          ctx.beginPath()
          ctx.moveTo(particle.x, particle.y)
          ctx.lineTo(mouseX, mouseY)
          
          // Make lines brighter on all devices
          ctx.strokeStyle = `rgba(163, 193, 228, ${0.6 * (1 - distance / 150)})`
          ctx.lineWidth = 1.2;
          ctx.stroke()
        }
      })

      animationFrameId = requestAnimationFrame(render)
    }

    render()

    return () => {
      window.removeEventListener("resize", setCanvasDimensions)
      document.removeEventListener("mousemove", handleMouseMove)
      document.removeEventListener("touchstart", handleTouchStart)
      document.removeEventListener("touchmove", handleTouchMove)
      cancelAnimationFrame(animationFrameId)
    }
  }, [])

  return <canvas ref={canvasRef} className="constellation-bg" />
}

const MobileMenu = ({ isOpen, toggleMenu }) => (
  <div className={`md:hidden ${isOpen ? 'block' : 'hidden'} absolute top-full left-0 right-0 bg-[#0f2137] z-50`}>
    <div className="flex flex-col items-center py-4 space-y-4">
      <NavItem href="/">Home</NavItem>
      <NavItem href="/learn-more">Learn More</NavItem>
      <NavItem href="/pricing">Pricing</NavItem>
      <NavItem href="/announcements">Announcements</NavItem>
      <NavItem href="/roadmap">Roadmap</NavItem>
    </div>
  </div>
);

// Define Card components
const Card = ({ className, children }) => (
  <div className={`bg-white rounded-lg shadow-md ${className}`}>{children}</div>
);

const CardHeader = ({ children }) => <div className="p-6">{children}</div>;
const CardContent = ({ children }) => <div className="px-6 py-2">{children}</div>;
const CardFooter = ({ children }) => <div className="p-6">{children}</div>;
const CardTitle = ({ className, children }) => (
  <h2 className={`text-xl font-semibold ${className}`}>{children}</h2>
);
const CardDescription = ({ className, children }) => (
  <p className={`mt-2 ${className}`}>{children}</p>
);

// Define Button component
const Button = ({ variant, className, children, ...props }) => (
  <button
    className={`px-4 py-2 rounded ${className} ${
      variant === 'link' ? 'bg-transparent' : 'bg-[#6a9ad4]'
    }`}
    {...props}
  >
    {children}
  </button>
);

// Define Badge component
const Badge = ({ variant, className, children }) => (
  <span
    className={`px-2 py-1 rounded-full text-sm font-semibold ${className} ${
      variant === 'secondary' ? 'bg-[#27548a] text-[#c5d8ed]' : ''
    }`}
  >
    {children}
  </span>
);

const Modal = ({ isOpen, onClose, title, content }) => {
  // Add a ref to handle click outside
  const modalRef = useRef(null);
  
  // Handle click outside to close
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };
    
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      // Prevent body scrolling when modal is open
      document.body.style.overflow = 'hidden';
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      // Restore body scrolling when modal is closed
      document.body.style.overflow = 'auto';
    };
  }, [isOpen, onClose]);
  
  // Handle escape key to close
  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    
    if (isOpen) {
      document.addEventListener('keydown', handleEscKey);
    }
    
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen, onClose]);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4 overflow-y-auto"
          role="dialog"
          aria-modal="true"
        >
          <motion.div
            ref={modalRef}
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 50, opacity: 0 }}
            className="bg-gradient-to-br from-[#a3c1e4] to-[#3b79be] rounded-lg p-0 w-full max-w-lg mx-auto my-4 max-h-[80vh] flex flex-col relative overflow-hidden"
            role="document"
          >
            <div className="p-6 pb-4 flex justify-between items-center">
              <h2 className="text-2xl font-bold text-[#280137] pr-8">{title}</h2>
              <button 
                onClick={onClose} 
                className="text-[#280137] hover:text-[#3b79be] absolute top-4 right-4 p-2 rounded-full hover:bg-white hover:bg-opacity-20 transition-colors" 
                aria-label="Close Modal"
              >
                <X size={24} />
              </button>
            </div>
            <div 
              className="px-6 text-[#280137] modal-content overflow-y-auto flex-grow [&_a]:text-white [&_a]:hover:text-[#a6b4c4] [&_a]:transition-colors [&_a]:duration-300 custom-scrollbar"
              style={{
                scrollbarWidth: 'thin',
                scrollbarColor: '#27548a #a3c1e4'
              }}
            >
              {content}
            </div>
            <div className="p-6 pt-4 text-center">
              <button
                onClick={onClose}
                className="bg-[#27548a] text-white px-6 py-2 rounded-lg hover:bg-[#3b79be] transition-colors"
              >
                Close
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const NechepsoText = ({ className }) => (
  <span className={`text-white ${className}`}>Nechepso</span>
);

const NavItem = ({ href, children }) => (
  <motion.a
    href={href}
    className="text-sm uppercase tracking-wider hover:text-[#6a9ad4] focus:text-[#6a9ad4] transition-colors focus:outline-none focus:ring-2 focus:ring-[#6a9ad4]"
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
    {children}
  </motion.a>
);

const NechepsoLogo = () => (
  <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold mb-4">
    <span className="font-outhill text-white">NechepsO</span>
  </h1>
);

const AnnouncementCard = ({ title, date, description, category, content, isNew }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const controls = useAnimation();

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView, controls]);

  return (
    <>
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 }
        }}
        transition={{ duration: 0.5 }}
        className="relative"
      >
        <Card className="bg-gradient-to-br from-[#a3c1e4] to-[#3b79be] bg-opacity-30 backdrop-blur-lg rounded-lg p-6 shadow-lg transform hover:scale-105 transition-all duration-300 relative z-10">
          {isNew && (
            <div className="absolute bottom-2 right-2 bg-white text-[#280137] font-bold py-1 px-3 z-20 rounded-lg">
              New
            </div>
          )}
          <CardHeader>
            <div className="flex justify-between items-start">
              <CardTitle className="text-2xl font-bold text-[#280137]">{title}</CardTitle>
              <Badge variant="secondary" className="bg-[#27548a] text-[#c5d8ed]">
                {category}
              </Badge>
            </div>
            <CardDescription className="text-[#280137] flex items-center">
              <Calendar className="w-4 h-4 mr-2" aria-hidden="true"/>
              {date}
            </CardDescription>
          </CardHeader>
          <CardContent>
            <p className="text-[#280137]">{description}</p>
          </CardContent>
          <CardFooter>
            <Button 
              variant="link" 
              className="text-white hover:text-[#3b79be] transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-[#6a9ad4]"
              onClick={() => setIsModalOpen(true)}
              aria-label={`Read more about ${title}`}
            >
              Read More <ArrowRight className="ml-2 w-4 h-4" aria-hidden="true"/>
            </Button>
          </CardFooter>
        </Card>
      </motion.div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={title}
        content={content}
      />
    </>
  );
};

const AnnouncementsPage = () => {
  const [scrollY, setScrollY] = useState(0);

  // Metadata logic for page title and description
  useEffect(() => {
    // Set the title of the page
    document.title = "Annoucements - Nechepso";

    // Set the meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Up to date news on everything Nechepso."
      );
    } else {
      const newMetaDescription = document.createElement("meta");
      newMetaDescription.name = "description";
      newMetaDescription.content =
        "Up to date news on everything Nechepso";
      document.head.appendChild(newMetaDescription);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const parallaxRef = useRef(null);

  useEffect(() => {
    const parallaxEffect = () => {
      const scrollPosition = window.pageYOffset;
      const parallaxElements = parallaxRef.current.querySelectorAll('.parallax');
      parallaxElements.forEach((el) => {
        const speed = el.dataset.speed;
        const yPos = -(scrollPosition * speed);
        el.style.transform = `translateY(${yPos}px)`;
      });
    };
    window.addEventListener('scroll', parallaxEffect);
    return () => window.removeEventListener('scroll', parallaxEffect);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#081628] via-[#2a5a94] to-[#8aa7c8] text-white overflow-hidden nechepso-page" role="main">
      
      {/* Interactive Constellation Background */}
      <ConstellationBackground />
      
      {/* Custom Cursor */}
      <CustomCursor />
      
      {/* Background Stars Parallax */}
      <div ref={parallaxRef} className="absolute inset-0 overflow-hidden" aria-hidden="true">
        {/* Remove the static stars since we now have the interactive constellation */}
      </div>

      <nav className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300`} aria-label="Primary Navigation">
        <div className={`absolute inset-0 transition-opacity duration-300 ${scrollY > 50 ? 'opacity-90' : 'opacity-0'}`}>
          <div className="absolute inset-0 bg-gradient-to-r from-[#8aa7c8] via-[#2a5a94] to-[#081628]"></div>
        </div>
        <div className={`absolute inset-0 transition-opacity duration-300 ${scrollY > 50 ? 'opacity-100' : 'opacity-0'}`}>
          <div className="absolute inset-0 backdrop-blur-md"></div>
        </div>
        <div className="container mx-auto px-4 py-6 flex justify-between items-center relative z-10">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="flex items-center"
          >
            <NechepsoLogo />
          </motion.div>
          <div className="hidden md:flex space-x-8">
            <NavItem href="/">Home</NavItem>
            <NavItem href="/learn-more">Learn More</NavItem>
            <NavItem href="/pricing">Pricing</NavItem>
            <NavItem href="/announcements">Announcements</NavItem>
            <NavItem href="/roadmap">Roadmap</NavItem>
          </div>
          <button className="md:hidden focus:outline-none focus:ring-2 focus:ring-[#6a9ad4]" onClick={() => setIsMenuOpen(!isMenuOpen)} aria-label="Toggle Menu" aria-expanded={isMenuOpen}>
            <Menu className="w-6 h-6 text-white" />
          </button>
        </div>
        <MobileMenu isOpen={isMenuOpen} toggleMenu={() => setIsMenuOpen(!isMenuOpen)} />
      </nav>

      <main className="container mx-auto px-4 pb-16 page-content">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="text-5xl md:text-7xl font-bold text-center mb-12 text-[#c5d8ed]"
        >
          Announcements
        </motion.h1>

        <motion.p
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="text-xl md:text-2xl text-center mb-12 max-w-3xl mx-auto text-[#e5eef7]"
        >
          Stay up to date with the latest news and updates from <NechepsoText className="font-bold" />
        </motion.p>
        <div className="grid md:grid-cols-2 gap-8">
        <AnnouncementCard
            title="Launching Nechepso to the Public!"
            date="February 20th, 2025"
            description="It's official, Nechepso will launch to the public for subscription on May 28th, 2025 just after NORWAC ends! Stay tuned for more details!"
            category="Nechepso"
            content={<div>
              <p>The other big news here as the second announcement - today we're excited to announce the launch of the Nechepso software will be happening at the end of May just as NORWAC comes to a close! Please make sure to continue to check the site, as well as coming to see us at our upcoming conference appearances, for special discounts on subscriptions!</p><br />
              <ul className="list-disc list-inside mt-2">
                <li>May 28th, 2025 at 7:30 AM EST (Yes, it's elected).</li>
                <li>Subscriptions can be purchased in three ways - monthly, biannual, and annual.</li>
                <li>We will be offering special discounts at conferences, and potentially through some partnerships</li>
              </ul>
              <p><br />We're very excited to finally be able to share with the world what we've been working on for so long! See you in May!</p>
            </div>}
            isNew={true}
        />
        <AnnouncementCard
            title="Beta Testing Arrival"
            date="February 20th, 2025"
            description="Beta testing is finally set to begin and continue from April-May 2025 leading into our public launch of the software."
            category="Update"
            content={<div>
              <p>We have two announcements today, the first is for all our pre-purchasers - beta testing will run from April-May 2025 as we prepare to launch the program to the public! We will be fixing any bugs as well as implementing new features (some planned, some requested).</p><br />
              <p>What this means for beta testers:</p>
              <ul className="list-disc list-inside mt-2">
                <li>The time is finally upon us! We've been sharing clips and other information with some of you so you have a feel for what to expect.</li>
                <li>If you haven't been able to see any of this yet, and you're a pre-purchaser, please send us an email so we can get you setup!</li>
                <li>We will be working directly with you all leading into our launch, and we can't wait!</li>
              </ul>
              <p><br />Please send us an email if you haven't heard from us, we've sent a bunch but apparently some people don't get them as they're supposed to. Talk soon!</p>
            </div>}
            isNew={true}
        />
        <AnnouncementCard
            title="Extended Presale Seats + Update"
            date="November 8th, 2024"
            description="We have added an additional 50 presale seats! Thank you all so much for joining the team! Also, a minor update on beta testing and software release."
            category="Update"
            content={<div>
              <p>Today we have added an extra 50 slots for presales after reaching our initial limit. Since the beta testing isn't ready yet we can take on a few more people. I know everyone has been waiting patiently for the software to release for beta testing and we sincerely thank you for you patience! When our frontend was delivered it was supposed to be ready to plug in and ship out, however this wasn't the case.</p><br />
              <p>I have been working with the design team & coding along side them on the frontend as I've tied things together. We were only behind slightly on our end due to the storm, but the UI wasn't what we expected it would be upon delivery so I have been working with the developers to get everything running the way we intended it to be!</p><br />
              <p>Here on the final stretch we've experienced a few timing setbacks but it's all coming together - it won't be much longer! If you haven't purchased a presale spot yet you should act now before it's too late!</p><br />
              <p>Thanks for your patience,</p><br />
              <p>- John</p>
            </div>}
            isNew={false}
        />
        <AnnouncementCard
            title="It's Official - We're Going to NORWAC 2025!"
            date="October 17th, 2024"
            description="The Thema Mundi Technologies team is excited to announce that we will be joining Patrick Watson as a vendor at NORWAC 2025!"
            category="Conference"
            content={<div>
              <p>We're excited to announce that we will be joining Patrick Watson as a vendor at NORWAC 2025!</p><br />
              <p>The event will happen from May 22nd 9:00 AM (Pre-conference) through May 26th 4:00 PM (Post-conference).</p><br />
              <p>NORWAC is family-run & one of the most beloved astrology conferences in the astrological community.</p><br />
              <p>Taking place in Tukwila, Washington, some of the things you can expect include:</p>
              <ul className="list-disc list-inside mt-2">
                <li>Some of the best astrological speakers out there</li>
                <li>A thorough list of workshops and events</li>
                <li>The vendor hall with many great vendors (including us!)</li>
              </ul>
              <p><br />If you're interested in attending, visit <a href="https://norwac.net/" target="_blank" rel="noopener noreferrer">the official website</a> for more information.</p><br />
              <p>Make sure you come and see us!</p>
            </div>}
            isNew={false}
        />
        <AnnouncementCard
            title="Nechepso at Convergence 2025!"
            date="October 17th, 2024"
            description="We will be at the Convergence 2025 Astrology Conference in Orlando, Florida as a vendor taking place March 7th-9th, 2025!"
            category="Conference"
            content={<div>
              <p>The TMT team will be a vendor at the Convergence 2025 Astrology Conference in Orlando, Florida!</p><br />
              <p>Conference begins on March 7th at 9:00 AM (Pre-conference) and ends on March 9th at 6:00 PM.</p><br />
              <p>Some of the features of the conference include:</p>
              <ul className="list-disc list-inside mt-2">
                <li>A phenomenal lineup of speakers</li>
                <li>A banquet with a DJ and a special guest performance</li>
                <li>Located at the Florida Hotel, adjacent to the Florida Mall</li>
              </ul>
              <p><br />This event is being put together by the fantastic team at Cosmic Patterns Software.</p><br />
              <p>For more information on how to attend, visit <a href="https://cosmicpatternsconference.com/index.html" target="_blank" rel="noopener noreferrer">the official website</a>.</p><br />
              <p>Can't wait to see you!</p>
            </div>}
            isNew={false}
        />
        <AnnouncementCard
            title="Catch the Team on the Celestial Compass!"
            date="October 1st, 2024"
            description="The TMT team will be on the Celestial Compass podcast with Kathy Biehl to discuss Nechepso and Third House on October 7th!"
            category="Podcast"
            content={<div>
              <p>Make sure to tune in to see the team on the Celestial Compass with Kathy Biehl!</p><br />
              <p>The show airs on Monday, October 7th, 2024 at 2:00 PM PDT/5:00 PM EDT.</p><br />
              <p>Some of the things we will be discussing include:</p><br />
              <ul className="list-disc list-inside mt-2">
                <li>Nechepso</li>
                <li>Third House</li>
                <li>What set's Nechepso apart from other astrology software</li>
                <li>What the future holds for both Nechepso and Third House</li>
              </ul>
              <p>It will be broadcast live from the <a href="https://www.youtube.com/@omtimestv" target="_blank" rel="noopener noreferrer">@omtimestv</a> YouTube channel.</p>
              <p>See you there!</p>
            </div>}
            isNew={false}
          />
          <AnnouncementCard
            title="Patrick Watson to speak at NORWAC 2025!"
            date="September 27th, 2024"
            description="Catch Patrick Watson speaking at NORWAC which is taking place from May 22nd-26th! Mark your calendar!."
            category="Conference"
            content={<div>
              <p>Next year catch Patrick Watson speaking at the NORWAC conference in Tukwila, Washington.</p><br />
              <p>The event runs between May 22nd and May 26th and features many great speakers and events.</p><br />
              <p>Here's what Patrick is slated to speak about at the conference:</p><br />
              <ul className="list-disc list-inside mt-2">
                <li>Exploring Planetary Activations</li>
                <li>Friday, May 23rd, 2025, 2:45 pm PDT</li><br />
                <li>Introduction to Babylonian Goal Years</li>
                <li>Sunday, May 25th, 2025, 3:45 pm PDT</li><br />
              </ul>
              <p>Registration will open for the conference on October 1st, 2024.</p>
              <p className="mt-4">For more information, visit <a href="https://norwac.net/" target="_blank" rel="noopener noreferrer">norwac.net</a> for additional details.</p>
            </div>}
            isNew={false}
          />
          <AnnouncementCard
            title="Updated Website with Nechepso Roadmap"
            date="September 15th, 2024"
            description="As things are heating up, we wanted to provide a roadmap for what to expect in the near future!"
            category="Update"
            content={<div>
              <p>We're excited to reveal our updated website, complete with an in-depth look at Nechepso's upcoming developments.</p><br />
              <p>The roadmap outlines key milestones, giving you a clear view of where we're heading and how we plan to get there.</p><br />
              <p>Here's a sneak peek of what you can expect:</p><br />
              <ul className="list-disc list-inside mt-2">
                    <li>Beta testing beginning soon!</li>
                    <li>Official launch of Nechepso 1.0</li>
                    <li>Launch of the open-source Meridian Codex atlas</li>
                    <li>Fully updated UI rich with new features for Third House</li>
              </ul>
              <p className="mt-4">Stay tuned for regular updates as we continue to build the future of astrology with Nechepso!</p>
            </div>}
          />
          <AnnouncementCard
            title="Nechepso Beta Test Signups"
            date="August 17th, 2024"
            description="We're starting to send out invitations to sign up for Nechepso's beta testing. If you've already purchased, make sure to secure your spot!"
            category="Nechepso"
            content={<div>
              <p>We're excited to begin the signup process for Nechepso's beta testing!</p><br />
              <p>If you've already paid for the software, now's your chance to join the beta test. We know the signup process can be lengthy, so we're giving everyone time to register, but we won't keep it open forever. Be on the lookout in your email for link to register and get started!</p><br />
              <p>As a beta tester, you'll have the opportunity to:</p>
              <ul className="list-disc list-inside mt-2">
                <li>Explore every feature of Nechepso and provide feedback before its implementation</li>
                <li>Test out the Nechepso AI before its release</li>
                <li>Provide invaluable feedback to help shape Nechepso's future</li>
                <li>With Nechepso - Beta testing is for life! Every new feature, forever, you get to test it first!</li>
              </ul>
              <p className="mt-4">Don't miss your chance to sign up and be part of the journey!</p>
            </div>}
          />
          <AnnouncementCard
            title="Third House is Live!"
            date="June 14th, 2024"
            description="Third House Social is now live for public testing! The first and only social network built exclusively for astrology. We value your feedback!"
            category="Third House"
            content={<div>
              <p>We're excited to announce the public testing phase of Third House, the first social network created specifically by astrologers, for astrology, featuring astrological tools and insights built directly into the platform.</p>
              <p>As we continue development, your feedback is crucial to help us refine the features and create a truly unique experience for the astrology community. We are looking to deploy a major update by December 2024.</p>
              <ul className="list-disc list-inside mt-2">
                <li>Explore astrological posts and insights in real-time</li>
                <li>Engage with a community of fellow astrology enthusiasts</li>
                <li>Be part of shaping the future of this groundbreaking network</li>
              </ul>
              <p className="mt-4">Visit us today at <a href="https://www.thirdhousesocial.com" target="_blank" rel="noopener noreferrer">www.thirdhousesocial.com</a> and join the astrological conversation!</p>
            </div>}
          />

          <AnnouncementCard
            title="Introducing: Nechepso & Nechepso AI"
            date="May 7th, 2024"
            description="Nechepso is here! Today we launch the first-ever demo of Nechepso AI for the world to see!"
            category="Nechepso"
            content={<div>
              <p>We're proud to introduce Nechepso, the next generation of astrology software, along with our first public demo of Nechepso AI!</p>
              <p>This demo showcases the unparalleled accuracy of Nechepso AI in astrological analysis, a leap beyond frontier models like ChatGPT, Claude, and Gemini in astrological knowledge.</p>
              <ul className="list-disc list-inside mt-2">
                <li>See Nechepso AI tackle the most specific sets of astrological data</li>
                <li>Explore how our AI sets a new standard in astrological interpretation</li>
                <li>Get a glimpse of the future of astrology software</li>
              </ul>
              <p className="mt-4">Watch the demo now: <a href="https://www.youtube.com/watch?v=kXucJu8I-SQ" target="_blank" rel="noopener noreferrer">Nechepso AI Demo</a></p>
            </div>}
          />
        </div>
      </main>

      <footer className="bg-[#2a5688] py-8 relative" aria-label="footer">
        <div className="container mx-auto px-4 text-center relative z-10">
          <p className="text-[#c5d8ed]">© 2024-2025 Thema Mundi Technologies. All rights reserved.</p>
        </div>
         <div className="absolute bottom-0 left-0 right-0 h-8 bg-repeat-x" style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='https://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon fill='%23c5d8ed' points='0,100 50,0 100,100'/%3E%3C/svg%3E")`,
          backgroundSize: '50px 100%',
          opacity: 0.2,
        }} aria-hidden="true"></div>
      </footer>
    </div>
  );
};

export default AnnouncementsPage;