import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation, useInView, useMotionValue, useSpring } from 'framer-motion';
import { Calendar, CheckCircle, Circle, Menu } from 'lucide-react';

const CustomCursor = () => {
  const cursorX = useMotionValue(-100)
  const cursorY = useMotionValue(-100)
  const cursorRotation = useMotionValue(0)
  const [prevPosition, setPrevPosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    const moveCursor = (e) => {
      // Calculate rotation based on movement direction
      const newX = e.clientX
      const newY = e.clientY
      
      if (prevPosition.x !== 0 && prevPosition.y !== 0) {
        const deltaX = newX - prevPosition.x
        const deltaY = newY - prevPosition.y
        
        if (Math.abs(deltaX) > 0 || Math.abs(deltaY) > 0) {
          const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI)
          cursorRotation.set(angle)
        }
      }
      
      setPrevPosition({ x: newX, y: newY })
      cursorX.set(newX)
      cursorY.set(newY)
    }
    
    document.addEventListener("mousemove", moveCursor)

    return () => {
      document.removeEventListener("mousemove", moveCursor)
    }
  }, [cursorX, cursorY, cursorRotation, prevPosition])

  const springConfig = { damping: 25, stiffness: 700 }
  const cursorXSpring = useSpring(cursorX, springConfig)
  const cursorYSpring = useSpring(cursorY, springConfig)
  const rotationSpring = useSpring(cursorRotation, { damping: 50, stiffness: 400 })

  return (
    <motion.div
      className="nechepso-cursor"
      style={{
        translateX: cursorXSpring,
        translateY: cursorYSpring,
        rotate: rotationSpring,
        x: "-50%",
        y: "-50%",
      }}
    >
      <div className="nechepso-cursor-arrow" />
    </motion.div>
  )
}

const ConstellationBackground = () => {
  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext("2d")
    let animationFrameId
    let particles = []
    let mouseX = 0
    let mouseY = 0

    // Set canvas dimensions
    const setCanvasDimensions = () => {
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight
    }

    setCanvasDimensions()
    window.addEventListener("resize", setCanvasDimensions)

    // Track mouse position on document instead of canvas
    const handleMouseMove = (e) => {
      mouseX = e.clientX
      mouseY = e.clientY
    }
    
    const handleTouchStart = (e) => {
      if (e.touches.length > 0) {
        mouseX = e.touches[0].clientX
        mouseY = e.touches[0].clientY
      }
    }
    
    const handleTouchMove = (e) => {
      if (e.touches.length > 0) {
        mouseX = e.touches[0].clientX
        mouseY = e.touches[0].clientY
      }
    }

    // Add event listeners to document instead of canvas
    document.addEventListener("mousemove", handleMouseMove)
    document.addEventListener("touchstart", handleTouchStart)
    document.addEventListener("touchmove", handleTouchMove)

    // Create particles
    const createParticles = () => {
      particles = []
      const isMobile = window.innerWidth < 768;
      const particleCount = isMobile 
        ? Math.floor((window.innerWidth * window.innerHeight) / 22500)
        : Math.floor((window.innerWidth * window.innerHeight) / 22500);

      for (let i = 0; i < particleCount; i++) {
        particles.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          size: Math.random() * 2 + 1,
          speedX: (Math.random() - 0.5) * 0.2,
          speedY: (Math.random() - 0.5) * 0.2,
          opacity: Math.random() * 0.5 + 0.3,
        })
      }
    }

    createParticles()

    // Draw particles and connections
    const render = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      // Draw particles
      particles.forEach((particle, index) => {
        // Update position
        particle.x += particle.speedX
        particle.y += particle.speedY

        // Wrap around edges
        if (particle.x < 0) particle.x = canvas.width
        if (particle.x > canvas.width) particle.x = 0
        if (particle.y < 0) particle.y = canvas.height
        if (particle.y > canvas.height) particle.y = 0

        // Draw particle
        ctx.beginPath()
        ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2)
        ctx.fillStyle = `rgba(255, 255, 255, ${particle.opacity})`
        ctx.fill()

        // Draw connections to nearby particles
        for (let j = index + 1; j < particles.length; j++) {
          const dx = particles[j].x - particle.x
          const dy = particles[j].y - particle.y
          const distance = Math.sqrt(dx * dx + dy * dy)

          if (distance < 100) {
            ctx.beginPath()
            ctx.moveTo(particle.x, particle.y)
            ctx.lineTo(particles[j].x, particles[j].y)
            
            // Make particle connections brighter on all devices
            ctx.strokeStyle = `rgba(106, 154, 212, ${0.25 * (1 - distance / 100)})`
            ctx.lineWidth = 0.7;
            ctx.stroke()
          }
        }

        // Draw connections to mouse
        const dx = mouseX - particle.x
        const dy = mouseY - particle.y
        const distance = Math.sqrt(dx * dx + dy * dy)
        
        if (distance < 150) {
          ctx.beginPath()
          ctx.moveTo(particle.x, particle.y)
          ctx.lineTo(mouseX, mouseY)
          
          // Make lines brighter on all devices
          ctx.strokeStyle = `rgba(163, 193, 228, ${0.6 * (1 - distance / 150)})`
          ctx.lineWidth = 1.2;
          ctx.stroke()
        }
      })

      animationFrameId = requestAnimationFrame(render)
    }

    render()

    return () => {
      window.removeEventListener("resize", setCanvasDimensions)
      document.removeEventListener("mousemove", handleMouseMove)
      document.removeEventListener("touchstart", handleTouchStart)
      document.removeEventListener("touchmove", handleTouchMove)
      cancelAnimationFrame(animationFrameId)
    }
  }, [])

  return <canvas ref={canvasRef} className="constellation-bg" />
}

const MobileMenu = ({ isOpen, toggleMenu }) => (
  <div className={`md:hidden ${isOpen ? 'block' : 'hidden'} absolute top-full left-0 right-0 bg-[#0f2137] z-50`}>
    <div className="flex flex-col items-center py-4 space-y-4">
      <NavItem href="/">Home</NavItem>
      <NavItem href="/learn-more">Learn More</NavItem>
      <NavItem href="/pricing">Pricing</NavItem>
      <NavItem href="/announcements">Announcements</NavItem>
      <NavItem href="/roadmap">Roadmap</NavItem>
    </div>
  </div>
);

const NechepsoText = ({ className }) => (
  <span className={`text-white ${className}`}>Nechepso</span>
);

const NavItem = ({ href, children }) => (
  <motion.a
    href={href}
    className="text-sm uppercase tracking-wider hover:text-[#6a9ad4] focus:text-[#6a9ad4] transition-colors focus:outline-none focus:ring-2 focus:ring-[#6a9ad4]"
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
    {children}
  </motion.a>
);

const NechepsoLogo = () => (
  <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold mb-4">
    <span className="font-outhill text-white">NechepsO</span>
  </h1>
);

const TimelineItem = ({ date, title, description, completed, isLast }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const controls = useAnimation();

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView, controls]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
      }}
      transition={{ duration: 0.5 }}
      className="flex mb-8 relative"
    >
      <div className="flex-shrink-0 w-32 text-right pr-8 py-3">
        <div className="flex items-center justify-end mb-2">
          <Calendar className="w-4 h-4 mr-2 text-[#c5d8ed]" aria-hidden="true" />
          <span className="text-sm text-[#c5d8ed]">{date}</span>
        </div>
      </div>
      <div className="flex-shrink-0 relative">
        <div className={`w-6 h-6 rounded-full ${completed ? 'bg-[#6a9ad4]' : 'bg-[#535f8d] border-2 border-[#6a9ad4]'} flex items-center justify-center z-10 relative`}>
          {completed ? (
            <CheckCircle className="w-4 h-4 text-[#280137]" aria-hidden="true"/>
          ) : (
            <Circle className="w-4 h-4 text-[#6a9ad4]" aria-hidden="true"/>
          )}
        </div>
        {!isLast && (
          <div className="absolute top-6 bottom-0 left-1/2 w-0.5 bg-[#6a9ad4] -translate-x-1/2" aria-hidden="true"></div>
        )}
      </div>
      <div className="flex-grow pl-8 py-3">
        <h3 className="text-xl font-bold text-[#c5d8ed] mb-2">
          {typeof title === 'string' ? title : title}
        </h3>
        <p className="text-[#e5eef7]">
          {typeof description === 'string' ? description : description}
        </p>
      </div>
    </motion.div>
  );
};

const TimelinePage = () => {
  const [scrollY, setScrollY] = useState(0);

  // Metadata logic for page title and description
  useEffect(() => {
    // Set the title of the page
    document.title = "Nechepso Astrology Software Development Roadmap | Release Timeline";

    // Set the meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Explore Nechepso's astrology software development roadmap and release timeline. Stay updated on upcoming features, beta testing opportunities, and official launch dates."
      );
    } else {
      const newMetaDescription = document.createElement("meta");
      newMetaDescription.name = "description";
      newMetaDescription.content =
        "Explore Nechepso's astrology software development roadmap and release timeline. Stay updated on upcoming features, beta testing opportunities, and official launch dates.";
      document.head.appendChild(newMetaDescription);
    }
    
    // Update Open Graph metadata
    let ogTitle = document.querySelector('meta[property="og:title"]');
    if (ogTitle) {
      ogTitle.setAttribute("content", "Nechepso Astrology Software Development Roadmap | Release Timeline");
    }
    
    let ogDesc = document.querySelector('meta[property="og:description"]');
    if (ogDesc) {
      ogDesc.setAttribute("content", "Explore Nechepso's astrology software development roadmap and release timeline. Stay updated on upcoming features and launch dates.");
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const parallaxRef = useRef(null);

  useEffect(() => {
    const parallaxEffect = () => {
      const scrollPosition = window.pageYOffset;
      const parallaxElements = parallaxRef.current.querySelectorAll('.parallax');
      parallaxElements.forEach((el) => {
        const speed = el.dataset.speed;
        const yPos = -(scrollPosition * speed);
        el.style.transform = `translateY(${yPos}px)`;
      });
    };
    window.addEventListener('scroll', parallaxEffect);
    return () => window.removeEventListener('scroll', parallaxEffect);
  }, []);

  const timelineItems = [
    {
      date: "May 28th, 2025",
      title: "Official Nechepso Launch",
      description: "Nechepso will officially launch for subscription to the public on May 28th, 2025!",
      completed: false,
    },
    {
      date: "May 23nd-26th, 2025",
      title: "Nechepso at NORWAC 2025",
      description: "Catch us at NORWAC 2025 in Tukwila, WA! We are vendors and will be showcasing Nechepso to the public.",
      completed: false,
    },
    {
      date: "May 23nd-26th, 2025",
      title: "Patrick Watson to speak at NORWAC",
      description: "Patrick Watson will be speaking at the NORWAC conference in Tukwila, Washington. He will be speaking about Babylonian Goal Years and Planetary Activations.",
      completed: false,
    },
    {
      date: "April-May 2025",
      title: "Nechepso Beta Testing",
      description: "We will be adding things as well as testing and tweaking everything internally in the months leading up into NORWAC and the official public release of the program.",
      completed: false,
    },
    {
      date: "March 7th-9th, 2025",
      title: "Convergence 2025 in Orlando, FL",
      description: "Catch the TMT team at Convergence 2025 in Orlando, FL! We will be present as a vendor showcasing Third House and Nechepso.",
      completed: false,
    },
    {
      date: "October 7th, 2024",
      title: "Celestial Compass Interview",
      description: "Catch the team on the Celestial Compass podcast talking about the future with Nechepso and Third House.",
      completed: true,
    },
    {
      date: "September 15th, 2024",
      title: "Private Live Stream For Customers",
      description: "The team hosts a private live stream for the customers who purchased the presale to give them the first glimpse of the software, what it will do, and what to expect next.",
      completed: true,
    },
    {
      date: "August 17th, 2024",
      title: "Beta Testing Sign Ups Begin",
      description: "The team begins sending out the beta testing invites to the people who signed up during the presale.",
      completed: true,
    },
    {
      date: "June 14th, 2024",
      title: "Third House Testing Launch",
      description: "The official launch of Third House, the site is live for testing and feedback from the public.",
      completed: true,
    },
    {
      date: "June 5th, 2024",
      title: "Introduction to Third House",
      description: "The team officially announced Third House, a new astrology social network built to connect the astrological community.",
      completed: true,
    },
    {
      date: "May 17th, 2024",
      title: "Live Q&A Survey Follow Up",
      description: "The team held a live Q&A survey to get feedback from the community on what they liked and what they didn't like about Nechepso.",
      completed: true,
    },
    {
      date: "May 7th, 2024",
      title: "Introduction of Nechepso & AI Demo",
      description: "We revealed to the world what it was we were working on, an AI-powered astrological software called Nechepso. We also did a live demo showing the AI in action.",
      completed: true,
    },
    {
      date: "April 22nd, 2024",
      title: "Initial AI Prototype Complete",
      description: "The first iteration of the Nechepso AI trained on Traditional Astrology, History, and Astrological Ethics is completed and ready for demo.",
      completed: true,
    },
    {
      date: "January 18th, 2024",
      title: "Thema Mundi Technologies is Born",
      description: "John, Patrick, and Nick filed to create their new company called Thema Mundi Technologies, for all of their future projects.",
      completed: true,
    },
    {
      date: "January 5th, 2024",
      title: "Initial Third House Prototype Complete.",
      description: "The very first iteration of Third House is completed and uploaded to the internet for internal testing.",
      completed: true,
    },
    {
      date: "December 21st, 2023",
      title: "It's Made Official",
      description: "John and Patrick decide to make Nechepso a reality, it's here Patrick suggests introducing Nick to the project where he joined the following week.",
      completed: true,
    },
    {
      date: "December 3rd, 2023",
      title: "Inception of Nechepso & Third House",
      description: "The idea for Nechepso is born. John Streaker reaches out to Patrick Watson to pitch the idea of a new astrology software to him and that's where the journey begins.",
      completed: true,
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#081628] via-[#2a5a94] to-[#8aa7c8] text-white overflow-hidden nechepso-page" role="main">
      {/* Interactive Constellation Background */}
      <ConstellationBackground />
      
      {/* Custom Cursor */}
      <CustomCursor />
      
      {/* Background Stars Parallax */}
      <div ref={parallaxRef} className="absolute inset-0 overflow-hidden" aria-hidden="true">
        {/* Remove the static stars since we now have the interactive constellation */}
      </div>
      
      <nav className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300`} aria-label="Primary Navigation">
        <div className={`absolute inset-0 transition-opacity duration-300 ${scrollY > 50 ? 'opacity-90' : 'opacity-0'}`}>
          <div className="absolute inset-0 bg-gradient-to-r from-[#a3c1e4] via-[#3776c1] to-[#0f2137]"></div>
        </div>
        <div className={`absolute inset-0 transition-opacity duration-300 ${scrollY > 50 ? 'opacity-100' : 'opacity-0'}`}>
          <div className="absolute inset-0 backdrop-blur-md"></div>
        </div>
        <div className="container mx-auto px-4 py-6 flex justify-between items-center relative z-10">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="flex items-center"
          >
            <NechepsoLogo />
          </motion.div>
          <div className="hidden md:flex space-x-8">
            <NavItem href="/">Home</NavItem>
            <NavItem href="/learn-more">Learn More</NavItem>
            <NavItem href="/pricing">Pricing</NavItem>
            <NavItem href="/announcements">Announcements</NavItem>
            <NavItem href="/roadmap">Roadmap</NavItem>
          </div>
          <button className="md:hidden focus:outline-none focus:ring-2 focus:ring-[#6a9ad4]" onClick={() => setIsMenuOpen(!isMenuOpen)} aria-label="Toggle Menu" aria-expanded={isMenuOpen}>
            <Menu className="w-6 h-6 text-white" />
          </button>
        </div>
        <MobileMenu isOpen={isMenuOpen} toggleMenu={() => setIsMenuOpen(!isMenuOpen)} />
      </nav>
      
      <main className="container mx-auto px-4 pb-16 page-content">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="text-5xl md:text-7xl font-bold text-center mb-12 text-[#c5d8ed]"
        >
          The Journey and<br />
          the Destination
        </motion.h1>

        <motion.p
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="text-xl md:text-2xl text-center mb-12 max-w-3xl mx-auto text-[#e5eef7]"
        >
          Follow the journey of <NechepsoText className="font-bold" /> from conception to launch
        </motion.p>

        <motion.p
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="text-xl md:text-xl text-center mb-12 max-w-xl mx-auto text-[#e5eef7]"
        >
          Be on the lookout for future projections of software releases and updates!
        </motion.p>

        <div className="max-w-4xl mx-auto">
          {timelineItems.map((item, index) => (
            <TimelineItem
              key={index}
              date={item.date}
              title={item.title}
              description={item.description}
              completed={item.completed}
              isLast={index === timelineItems.length - 1}
            />
          ))}
        </div>
      </main>

      <footer className="bg-[#2a5688] py-8 relative" aria-label="footer">
        <div className="container mx-auto px-4 text-center relative z-10">
          <p className="text-[#c5d8ed]">© 2024-2025 Thema Mundi Technologies. All rights reserved.</p>
        </div>
        <div
          className="absolute bottom-0 left-0 right-0 h-8 bg-repeat-x"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='https://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon fill='%23c5d8ed' points='0,100 50,0 100,100'/%3E%3C/svg%3E")`,
            backgroundSize: '50px 100%',
            opacity: 0.2,
          }}
          aria-hidden="true"
        ></div>
      </footer>
    </div>
  );
};

export default TimelinePage;