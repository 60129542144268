import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import EnhancedNechepsoHomePage from './components/EnhancedNechepsoHomePage';
import PricingPage from './components/PricingPage';
import LearnMorePage from './components/LearnMorePage';
import AnnouncementPage from './components/AnnouncementPage';
import TimelinePage from './components/TimelinePage';
import ConvergenceQrLanding from "./components/ConvergenceQrLanding";
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<EnhancedNechepsoHomePage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/learn-more" element={<LearnMorePage />} />
        <Route path="/announcements" element={<AnnouncementPage />} />
        <Route path="/roadmap" element={<TimelinePage />} />
        <Route path="/convergence2025" element={<ConvergenceQrLanding />} />
        </Routes>
      <SpeedInsights />
      <Analytics />
    </Router>
  );
}

export default App;