import '../App.css';
import { useState, useEffect, useRef } from "react"
import { motion, useScroll, useTransform, useSpring, useMotionValue } from "framer-motion"
import { Sparkles, ChevronDown, Mail, Check, Calendar, Command, RefreshCw, Layers } from "lucide-react"

// Custom cursor component
const CustomCursor = () => {
  const cursorX = useMotionValue(-100)
  const cursorY = useMotionValue(-100)

  useEffect(() => {
    const moveCursor = (e) => {
      cursorX.set(e.clientX)
      cursorY.set(e.clientY)
    }
    window.addEventListener("mousemove", moveCursor)

    return () => {
      window.removeEventListener("mousemove", moveCursor)
    }
  }, [cursorX, cursorY])

  const springConfig = { damping: 25, stiffness: 700 }
  const cursorXSpring = useSpring(cursorX, springConfig)
  const cursorYSpring = useSpring(cursorY, springConfig)

  return (
    <motion.div
      className="custom-cursor"
      style={{
        translateX: cursorXSpring,
        translateY: cursorYSpring,
        x: "-50%",
        y: "-50%",
      }}
    />
  )
}

// Animated gradient text
const GradientText = ({ children, className = "" }) => {
  return <span className={`gradient-text ${className}`}>{children}</span>
}

// Animated constellation background
const ConstellationBackground = () => {
  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext("2d")
    let animationFrameId
    let particles = []
    let mouseX = 0
    let mouseY = 0

    // Set canvas dimensions
    const setCanvasDimensions = () => {
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight
    }

    setCanvasDimensions()
    window.addEventListener("resize", setCanvasDimensions)

    // Track mouse position
    const handleMouseMove = (e) => {
      mouseX = e.clientX
      mouseY = e.clientY
    }
    
    const handleTouchStart = (e) => {
      if (e.touches.length > 0) {
        mouseX = e.touches[0].clientX
        mouseY = e.touches[0].clientY
      }
    }
    
    const handleTouchMove = (e) => {
      if (e.touches.length > 0) {
        mouseX = e.touches[0].clientX
        mouseY = e.touches[0].clientY
      }
    }

    window.addEventListener("mousemove", handleMouseMove)
    window.addEventListener("touchstart", handleTouchStart)
    window.addEventListener("touchmove", handleTouchMove)

    // Create particles
    const createParticles = () => {
      particles = []
      const isMobile = window.innerWidth < 768;
      const particleCount = isMobile 
        ? Math.floor((window.innerWidth * window.innerHeight) / 22500)
        : Math.floor((window.innerWidth * window.innerHeight) / 22500);

      for (let i = 0; i < particleCount; i++) {
        particles.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          size: Math.random() * 2 + 1,
          speedX: (Math.random() - 0.5) * 0.2,
          speedY: (Math.random() - 0.5) * 0.2,
          opacity: Math.random() * 0.5 + 0.3,
        })
      }
    }

    createParticles()

    // Draw particles and connections
    const render = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      // Draw particles
      particles.forEach((particle, index) => {
        // Update position
        particle.x += particle.speedX
        particle.y += particle.speedY

        // Wrap around edges
        if (particle.x < 0) particle.x = canvas.width
        if (particle.x > canvas.width) particle.x = 0
        if (particle.y < 0) particle.y = canvas.height
        if (particle.y > canvas.height) particle.y = 0

        // Draw particle
        ctx.beginPath()
        ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2)
        ctx.fillStyle = `rgba(255, 255, 255, ${particle.opacity})`
        ctx.fill()

        // Draw connections to nearby particles
        for (let j = index + 1; j < particles.length; j++) {
          const dx = particles[j].x - particle.x
          const dy = particles[j].y - particle.y
          const distance = Math.sqrt(dx * dx + dy * dy)

          if (distance < 100) {
            ctx.beginPath()
            ctx.moveTo(particle.x, particle.y)
            ctx.lineTo(particles[j].x, particles[j].y)
            
            // Make particle connections brighter on all devices
            ctx.strokeStyle = `rgba(106, 154, 212, ${0.25 * (1 - distance / 100)})`
            ctx.lineWidth = 0.7;
            ctx.stroke()
          }
        }

        // Draw connections to mouse
        const dx = mouseX - particle.x
        const dy = mouseY - particle.y
        const distance = Math.sqrt(dx * dx + dy * dy)
        
        if (distance < 150) {
          ctx.beginPath()
          ctx.moveTo(particle.x, particle.y)
          ctx.lineTo(mouseX, mouseY)
          
          // Make lines brighter on all devices
          ctx.strokeStyle = `rgba(163, 193, 228, ${0.6 * (1 - distance / 150)})`
          ctx.lineWidth = 1.2;
          ctx.stroke()
        }
      })

      animationFrameId = requestAnimationFrame(render)
    }

    render()

    return () => {
      window.removeEventListener("resize", setCanvasDimensions)
      window.removeEventListener("mousemove", handleMouseMove)
      window.removeEventListener("touchstart", handleTouchStart)
      window.removeEventListener("touchmove", handleTouchMove)
      cancelAnimationFrame(animationFrameId)
    }
  }, [])

  return <canvas ref={canvasRef} className="constellation-bg" />
}

// 3D Card component
const Card3D = ({ children, className = "" }) => {
  const cardRef = useRef(null)
  const [rotateX, setRotateX] = useState(0)
  const [rotateY, setRotateY] = useState(0)

  const handleMouseMove = (e) => {
    if (!cardRef.current) return

    const card = cardRef.current
    const rect = card.getBoundingClientRect()
    const x = e.clientX - rect.left
    const y = e.clientY - rect.top

    const centerX = rect.width / 2
    const centerY = rect.height / 2

    const rotateXValue = (y - centerY) / 20
    const rotateYValue = (centerX - x) / 20

    setRotateX(rotateXValue)
    setRotateY(rotateYValue)
  }

  const handleTouchMove = (e) => {
    if (!cardRef.current || e.touches.length < 1) return

    const card = cardRef.current
    const rect = card.getBoundingClientRect()
    const touch = e.touches[0]
    const x = touch.clientX - rect.left
    const y = touch.clientY - rect.top

    const centerX = rect.width / 2
    const centerY = rect.height / 2

    const rotateXValue = (y - centerY) / 20
    const rotateYValue = (centerX - x) / 20

    setRotateX(rotateXValue)
    setRotateY(rotateYValue)
  }

  const handleMouseLeave = () => {
    setRotateX(0)
    setRotateY(0)
  }

  const handleTouchEnd = () => {
    setRotateX(0)
    setRotateY(0)
  }

  return (
    <motion.div
      ref={cardRef}
      className={`card-3d ${className}`}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      style={{
        transformStyle: "preserve-3d",
        transform: `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`,
      }}
      whileHover={{ scale: 1.02 }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <div className="card-bg" />
      <div className="card-border" />
      <div className="card-content">{children}</div>
    </motion.div>
  )
}

// Feature card with hover effect
const FeatureCard = ({ icon, title, description }) => {
  return (
    <Card3D className="feature-card">
      <div className="feature-card-inner">
        <div className="feature-icon">{icon}</div>
        <h3 className="feature-title">{title}</h3>
        <p className="feature-description">{description}</p>
      </div>
    </Card3D>
  )
}

// Animated section divider
const SectionDivider = () => {
  return (
    <div className="section-divider">
      <div className="divider-inner">
        <div className="divider-line-1"></div>
        <div className="divider-line-2"></div>
      </div>
    </div>
  )
}

// Custom button component
const Button = ({ children, onClick, className = "" }) => {
  return (
    <button onClick={onClick} className={`custom-button ${className}`}>
      {children}
    </button>
  )
}

// Main component
const ConvergenceQrLanding = () => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [expandedVideo, setExpandedVideo] = useState(null)

  const showcaseImages = [
    "/images/WorkspaceDark.png",
    "/images/CirclesUI.png",
    "/images/Journal1.png",
    "/images/Journal2.png",
    "/images/WorkspaceLight.png",
    "/images/TarotTable.png",
    "/images/TarotSpread.png",
    "/images/snapshot2.png",
    "/images/WorkspaceTwilight.png",
    "/images/Multimedia.png",
    "/images/Numerology.png",
    "/images/Snapshot.png",
  ]

  // Auto-rotate slides
  useEffect(() => {
    const slideInterval = setInterval(() => {
      changeSlide((currentSlide + 1) % showcaseImages.length)
    }, 5000) // Change slide every 5 seconds

    return () => clearInterval(slideInterval)
  }, [currentSlide, showcaseImages.length])

  const changeSlide = (index) => {
    setIsTransitioning(true)
    setTimeout(() => {
      setCurrentSlide(index)
      setIsTransitioning(false)
    }, 300) // Half of the CSS transition time
  }

  const nextSlide = () => {
    changeSlide((currentSlide + 1) % showcaseImages.length)
  }

  const prevSlide = () => {
    changeSlide((currentSlide - 1 + showcaseImages.length) % showcaseImages.length)
  }

  const { scrollYProgress } = useScroll()
  const opacity = useTransform(scrollYProgress, [0, 0.2], [1, 0])
  const scale = useTransform(scrollYProgress, [0, 0.2], [1, 0.8])

  // Video handlers
  const handleVideoPlay = (index) => {
    setExpandedVideo(index)
    // Ensure the expanded video area is visible by scrolling to it
    setTimeout(() => {
      const expandedArea = document.getElementById("expanded-video-area")
      if (expandedArea) {
        expandedArea.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }, 100)
  }
 
  const handleVideoEnd = () => {
    setExpandedVideo(null)
  }

  const handleVideoClose = () => {
    setExpandedVideo(null)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError("") // Clear previous errors
    setIsLoading(true) // Start loading

    if (!email || !/^\S+@\S+\.\S+$/.test(email)) {
      setError("Please enter a valid email address")
      setIsLoading(false)
      return
    }

    try {
      const apiUrl = window.location.origin + '/api/subscribe';
      console.log('Submitting to API URL:', apiUrl);
      console.log('Submitting form data:', { name, email });

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name, email }),
      });

      console.log('Response status:', response.status);
      console.log('Response status text:', response.statusText);

      const responseText = await response.text();
      console.log('Raw response:', responseText);

      let responseData;
      try {
        responseData = JSON.parse(responseText);
      } catch (jsonError) {
        console.error('JSON parse error:', jsonError);
        setError('Unexpected server response. Please try again later.');
        setIsLoading(false);
        return;
      }

      if (!response.ok) {
        setError(responseData.message || 'Server error. Please try again later.');
        setIsLoading(false);
        return;
      }

      setSubmitted(true);
      setEmail('');
      setName('');
    } catch (err) {
      console.error('Network error:', err);
      setError('Connection error. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="convergence-landing">
      <CustomCursor />
      <ConstellationBackground />

      {/* Hero Section */}
      <section className="hero-section">
        <motion.div className="hero-bg" style={{ opacity, scale }}>
          <div className="hero-glow" />
        </motion.div>

        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="hero-image-container"
          >
            <motion.img
              src="/images/convergence2025.png"
              alt="Convergence 2025 Conference"
              className="hero-image"
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              transition={{ 
                duration: 0.8,
                ease: "easeOut"
              }}
              whileHover={{ 
                scale: 1.02,
                transition: { duration: 0.3 }
              }}
            />
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
            className="hero-content"
          >
            <h1 className="hero-title">
              <GradientText>Experience the Future</GradientText>
            </h1>

            <p className="hero-description">
              The seal was broken & you chose to take the journey. As a result, the scroll has led you here—your exclusive gateway to <span className="highlight">Nechepso</span>.
            </p>

            <motion.div
              className="hero-cta"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 1 }}
            >
              <Button
                onClick={() => document.getElementById("features").scrollIntoView({ behavior: "smooth" })}
                className="explore-button"
              >
                <span className="button-text">Continue The Journey</span>
                <span className="button-bg"></span>
              </Button>
            </motion.div>
          </motion.div>
        </div>
        
        <motion.div
          className="scroll-indicator"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1.5 }}
        >
          <ChevronDown className="scroll-icon" />
        </motion.div>
      </section>

      <SectionDivider />

      {/* Features Section */}
      <section id="features" className="features-section">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="section-header"
          >
            <h2 className="section-title">
              <GradientText>Crafted for Clarity, Engineered for Excellence</GradientText>
            </h2>
            <p className="section-description">Experience the fluidity of modern desktop design, the promise of continuous evolution, and depth that extends far beyond just creating charts.</p>
          </motion.div>

          <div className="features-grid">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.1 }}
              className="feature-item"
            >
              <FeatureCard
                icon={<Command className="icon" />}
                title="Effortless Command"
                description="Navigate through a smooth, responsive interface designed to anticipate your needs. Flexible capabilities become an extension of your curiosity, not a barrier to it."
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="feature-item"
            >
              <FeatureCard
                icon={<RefreshCw className="icon" />}
                title="Responsive Development"
                description="Nechepso is a living platform that grows more insightful and capable in response to community needs and discoveries, keeping your toolkit fully updated and at the cutting edge."
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="feature-item"
            >
              <FeatureCard
                icon={<Layers className="icon" />}
                title="Depth Beyond Dashboards"
                description="Reimagining what is possible with a platform where charts are just one component of the complete experience. Insights from one area enhance discoveries across your entire practice."
              />
            </motion.div>
          </div>

          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="feature-showcase"
          >
            <div className="showcase-bg" />
            <div className="showcase-content">
              <img 
                src={showcaseImages[currentSlide]} 
                alt="Nechepso Interface" 
                className="showcase-image" 
                style={{ opacity: isTransitioning ? 0 : 1 }}
              />
              
              <button 
                className="slider-nav slider-prev" 
                onClick={prevSlide} 
                aria-label="Previous slide"
              >
                &lt;
              </button>
              <button 
                className="slider-nav slider-next" 
                onClick={nextSlide} 
                aria-label="Next slide"
              >
                &gt;
              </button>
              
              <div className="slider-indicators">
                {showcaseImages.map((_, index) => (
                  <button
                    key={index}
                    className={`slider-indicator ${currentSlide === index ? "active" : ""}`}
                    onClick={() => changeSlide(index)}
                    aria-label={`Go to slide ${index + 1}`}
                  />
                ))}
              </div>
              
              <div className="showcase-overlay" />
            </div>
            <div className="showcase-text">
              <h3 className="showcase-title">Experience the Next Generation</h3>
              <p className="showcase-description">Nechepso combines visual appeal with operational ease and technical sophistication.</p>
            </div>
          </motion.div>
        </div>
      </section>

      <SectionDivider />

      {/* Video Showcase Section */}
      <section className="video-showcase-section">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="section-header"
          >
            <h2 className="section-title">
              <GradientText>Fortune Favors the Intuitive</GradientText>
            </h2>
            <p className="section-description"><span className="highlight">Nechepso</span> redefines astrology software—watch how its powerful tools and effortless interface make even the most complex tasks feel simple.</p>
          </motion.div>

          {/* Expanded video area - this is where videos will expand to */}
          {expandedVideo !== null && (
            <div className="expanded-video-container" id="expanded-video-area">
              <div className="expanded-video-wrapper">
                <video 
                  className="expanded-video" 
                  src={expandedVideo === 0 ? "/videos/QuickDisplay.mp4" : 
                       expandedVideo === 1 ? "/videos/InfoPopups.mp4" : 
                       "/videos/EasyColor.mp4"}
                  controls 
                  autoPlay
                  poster={expandedVideo === 0 ? "/images/WorkspaceLight.png" : 
                          expandedVideo === 1 ? "/images/WorkspaceDark.png" : 
                          "/images/WorkspaceTwilight.png"}
                  onEnded={handleVideoEnd}
                  onPause={handleVideoEnd}
                />
                <button className="video-close-btn" onClick={handleVideoClose}>
                  ×
                </button>
              </div>
              <div className="expanded-video-content">
                <h3 className="video-title">
                  {expandedVideo === 0 ? "Quick Display" : 
                   expandedVideo === 1 ? "Information Popups" : 
                   "Fine-Tune with Ease"}
                </h3>
                <p className="video-description">
                  {expandedVideo === 0 ? "Explore the intuitive design and powerful features of Nechepso's main interface." : 
                   expandedVideo === 1 ? "See how Nechepso's analytics capabilities transform your data into actionable insights." : 
                   "Discover how to personalize Nechepso to match your unique workflow and preferences."}
                </p>
              </div>
            </div>
          )}

          <div className="video-grid">
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.1 }}
              className="video-card"
            >
              <div className="video-card-inner">
                <div className="video-wrapper">
                  <div className="video-thumbnail" onClick={() => handleVideoPlay(0)}>
                    <img 
                      src="/images/WorkspaceLight.png" 
                      alt="Quick Display" 
                      className="video-poster" 
                    />
                    <div className="video-play-button"></div>
                  </div>
                </div>
                <div className="video-content">
                  <h3 className="video-title">Quick Display</h3>
                  <p className="video-description">With the Quick Display menu, what once seemed complex is now just a few clicks away.</p>
                </div>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="video-card"
            >
              <div className="video-card-inner">
                <div className="video-wrapper">
                  <div className="video-thumbnail" onClick={() => handleVideoPlay(1)}>
                    <img 
                      src="/images/WorkspaceDark.png" 
                      alt="Information Popups" 
                      className="video-poster" 
                    />
                    <div className="video-play-button"></div>
                  </div>
                </div>
                <div className="video-content">
                  <h3 className="video-title">Information Popups</h3>
                  <p className="video-description">Nechepso's Information Popups redefine chart interaction—putting essential details where you need them, when you need them.</p>
                </div>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.3 }}
              className="video-card"
            >
              <div className="video-card-inner">
                <div className="video-wrapper">
                  <div className="video-thumbnail" onClick={() => handleVideoPlay(2)}>
                    <img 
                      src="/images/WorkspaceTwilight.png" 
                      alt="Fine-Tune with Ease" 
                      className="video-poster" 
                    />
                    <div className="video-play-button"></div>
                  </div>
                </div>
                <div className="video-content">
                  <h3 className="video-title">Fine-Tune with Ease</h3>
                  <p className="video-description">Click, edit, done—customize charts instantly with on-screen editing and right-click color control.</p>
                </div>
              </div>
            </motion.div>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="video-section-cta"
          >
            <Button
              onClick={() => document.getElementById("experts").scrollIntoView({ behavior: "smooth" })}
              className="showcase-button"
            >
              Learn More
            </Button>
          </motion.div>
        </div>
      </section>

      <SectionDivider />

      {/* Experts Section */}
      <section id="experts" className="experts-section">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="section-header"
          >
            <h2 className="section-title">
              <GradientText>Built With Insight, Backed by Experience</GradientText>
            </h2>
            <p className="section-description">Created by astrologers, for astrologers—<span className="highlight">Nechepso</span> is shaped from the ground up by two of the world's most respected astrological authorities, Nick Dagan Best and Patrick Watson, uniting two of the brightest leaders in the world of astrology to develop a software destined to revolutionize the field.</p>
          </motion.div>

          <div className="experts-grid">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
              className="expert-item"
            >
              <Card3D className="expert-card">
                <div className="expert-content">
                  <div className="expert-image-container">
                    <div className="expert-image-glow" />
                    <img src="/images/nick.jpg" alt="Nick Dagan Best" className="expert-image" />
                  </div>
                  <div className="expert-info">
                    <h3 className="expert-name">Nick Dagan Best</h3>
                    <p className="expert-title">World-renowned professional astrologer, NCGR-PAA III with 30 years of experience</p>
                    <p className="expert-bio">
                    Renowned as the 'Human Ephemeris' and author of "URANU.S.A.", Nick has pioneered neo-Babylonian astrology and brings decades of research and expertise to the Nechepso project.
                      research and expertise to the Nechepso project.
                    </p>
                    <div className="expert-contributions">
                      <h4 className="contributions-title">Work & Influence:</h4>
                      <ul className="contributions-list">
                        <li>Co-Author of the Nechepso AI's handwritten training data</li>                        
                        <li>Helped shape the overall functionality and operation of the software</li>
                        <li>Helps shape Nechepso's voice, identity and public presence through strategic branding and artistic direction</li>
                        <li>Integrated his ground-breaking synodic cycle research throughout the program</li>
                        <li>Provided personal chart database with over 40,000 charts</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Card3D>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
              className="expert-item"
            >
              <Card3D className="expert-card">
                <div className="expert-content">
                  <div className="expert-image-container">
                    <div className="expert-image-glow" />
                    <img src="/images/patrick.png" alt="Patrick Watson" className="expert-image" />
                  </div>
                  <div className="expert-info">
                    <h3 className="expert-name">Patrick Watson</h3>
                    <p className="expert-title">Professional certified astrologer with 20+ years of study, CAPISAR President</p>
                    <p className="expert-bio">
                    Known for his wit and wisdom, Patrick makes astrology both insightful and entertaining by combining modern and ancient astrological techniques & creating innovative tools to explore Hellenistic methods that now form the foundation of Nechepso.
                    </p>
                    <div className="expert-contributions">
                      <h4 className="contributions-title">Work & Influence:</h4>
                      <ul className="contributions-list">
                        <li>Co-Author of the Nechepso AI's handwritten training data</li>
                        <li>Co-Designed the Nechepso user interface, tools, & features while helping shape the overall functionality of Nechepso</li>
                        <li>Assisting with coding the backend and frontend of the program</li>
                        <li>Developed program functions for rectifications, elections, circumambulations, and more</li>
                        <li>Provided personal chart interpretations to be included with the software</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Card3D>
            </motion.div>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="experts-cta"
          >
            <Button
              onClick={() => document.getElementById("offer").scrollIntoView({ behavior: "smooth" })}
              className="explore-button"
            >
              <span className="button-text">Get Your Exclusive Offer</span>
              <span className="button-bg"></span>
            </Button>
          </motion.div>
        </div>
      </section>

      <SectionDivider />

      {/* Offer Section */}
      <section id="offer" className="offer-section">
        <div className="offer-bg">
          <div className="offer-glow" />
        </div>

        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="section-header"
          >
            <h2 className="section-title">
              <GradientText>Your Exclusive Invitation Awaits</GradientText>
            </h2>
            <p className="section-description">
              As a Convergence 2025 attendee, you're eligible for special early access pricing
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="offer-container"
          >
            <Card3D className="offer-card">
              <div className="offer-grid">
                <div className="offer-details">
                  <h3 className="offer-title">
                    <Sparkles className="offer-icon" />
                    <span>Exclusive Conference Discount</span>
                  </h3>

                  <ul className="offer-list">
                    <li className="offer-item">
                      <div className="offer-badge">
                        <Check className="badge-icon" />
                      </div>
                      <div className="offer-text">
                        <h4 className="offer-item-title">Bulk Discount</h4>
                        <p className="offer-item-description">Get 1.5 months FREE when you pay for 1.5 months (50% savings)</p>
                      </div>
                    </li>

                    <li className="offer-item">
                      <div className="offer-badge">
                        <Check className="badge-icon" />
                      </div>
                      <div className="offer-text">
                        <h4 className="offer-item-title">Incremental Discount</h4>
                        <p className="offer-item-description">Get 33% off your first 3 months, month to month</p>
                      </div>
                    </li>

                    <li className="offer-item">
                      <div className="offer-badge">
                        <Calendar className="badge-icon" />
                      </div>
                      <div className="offer-text">
                        <h4 className="offer-item-title">Finish The Journey</h4>
                        <p className="offer-item-description">
                          Sales begin May 1st, 2025 - register now to secure your exclusive discount when the date arrives
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="form-container">
                  <div className="form-bg" />
                  <div className="form-content">
                    <h4 className="form-title">
                      <Mail className="form-icon" />
                      <span>Register for Your Discount</span>
                    </h4>

                    {!submitted ? (
                      <form onSubmit={handleSubmit} className="email-form">
                        <div className="form-group">
                          <label htmlFor="name" className="form-label">
                            Name
                          </label>
                          <input
                            id="name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Your Name"
                            className="form-input"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="email" className="form-label">
                            Email Address
                          </label>
                          <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="your@email.com"
                            className="form-input"
                            required
                          />
                          {error && <p className="form-error">{error}</p>}
                        </div>
                        <button 
                          type="submit" 
                          className="form-button"
                          disabled={isLoading}
                        >
                          {isLoading ? 'Submitting...' : 'Secure Your Discount'}
                        </button>
                        <p className="form-disclaimer">
                          We'll email your exclusive discount code on May 1st when sales begin.
                        </p>
                      </form>
                    ) : (
                      <div className="form-success">
                        <div className="success-icon-container">
                          <Check className="success-icon" />
                        </div>
                        <h5 className="success-title">Thank You!</h5>
                        <p className="success-message">
                          Your discount code will be sent to your email on May 1st, 2025.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Card3D>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="final-cta"
          >
            <h2 className="final-title">
              <GradientText>Chart a New Course with <span className="highlight">Nechepso</span></GradientText>
            </h2>
            <a href="https://www.nechepso.com" target="_blank" rel="noopener noreferrer" className="website-link">
              <span className="link-text">
                Nechepso.com
              </span>
              <span className="link-glow"></span>
            </a>
          </motion.div>
        </div>
      </section>

      {/* Footer */}
      <footer className="site-footer">
        <div className="container">
          <p className="footer-copyright">© 2024-2025 Thema Mundi Technologies. All rights reserved.</p>
          <p className="footer-link">
            <a href="https://www.nechepso.com" className="website-url">
              www.nechepso.com
            </a>
          </p>
        </div>
      </footer>
    </div>
  )
}

export default ConvergenceQrLanding